#footer {
  background: black;
  padding: 0;
  color: #eee;
  font-size: 14px;
  padding-bottom: 20px;
  margin-top: 100px;
}

#footer .footer-top {
  background: black;
  padding: 60px 0 0 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 26px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  color: #fff;
}

#footer .footer-top .footer-info img {
  height: 279px;
  margin-bottom: -84px;
  margin-top: -118px;
  margin-left: -30px;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: #fff;
}

#footer .footer-top .social-links a {
  display: inline-block;
  color: #eee;
  margin-right: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

#footer .footer-top .social-links a i {
  line-height: 0;
  font-size: 16px;
}

#footer .footer-top h4 {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
  border-bottom: 2px solid #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 5px;
  color: #fff;
  font-size: 18px;
}

#footer .footer-top .footer-links ul li {
  border-bottom: 1px solid #fff;
  padding: 10px 0;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #eee;
  text-decoration: none;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
  line-height: 26px;
}

#footer .copyright {
  text-align: center;
  font-size: 22px;
  padding-top: 30px;
}

@media (max-width:768px) {
  #footer .copyright {
    font-size: 16px;
  }
}