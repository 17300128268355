.blogs-details{
    margin:150px auto auto auto;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
.blogs-details h4{
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
}



@media (max-width:768px) {
    .blogs-details h4{
        font-size: 16px !important;
    }

    .blogs-details p{
        font-size: 14px !important;
    }
}