.service-page {
  z-index: 10;
  color: white;
  margin-top: 110px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.service-head {
  margin-top: 180px;
  color: #fff;
  z-index: 10;
  text-align: center;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

@media (max-width: 768px) {
  .service-head {
    font-size: 30px !important;
  }
  .service-page .minor-head2 {
    font-size: 16px !important;
  }
  .service-page .minor-p {
    font-size: 14px !important;
  }
  .service-page .alink {
    font-size: 14px !important;
  }
}

.service-page .brand-img {
  margin-bottom: 20px;
  height: 65px;
}

.service-page .minor-head2 {
  display: flex !important;
  flex-wrap: nowrap !important ;
  font-size: 18px;
  height: 35px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

.service-page .box {
  margin-top: 25px;
  position: relative;
}

.service-page .minor-p {
  padding-bottom: 0px;
}

@media (min-width: 768px) and (max-width: 1201px) {
  .service-page .box {
    margin-top: 110px;
  }
}
