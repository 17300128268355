 
 #hero {
  position: relative;
    height:100vh;
    width:100%;
   overflow: hidden;
}

.video{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  object-fit: cover;
  width: 100%;
  height:100%
  
}




  
  .para{
    font-family: 'Montserrat', sans-serif;
    font-size:20px;
    font-weight:800;
  }

  .consulting{
    background-color: black;
    padding-top:50px;
}


.btn-c{
  background-color: transparent;
  color:#fff;
  padding:14px 20px;
  border:2px solid #fff;
  border-radius: 55px;
  transition: 0.4s ease-in-out;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
text-transform:uppercase;
}

.btn-c:hover{
  background-color: #fff;
  color:black;
}



.consult-button{
font-size:20px;
padding-top: 10px;

}

.consult-button a{

text-decoration: none;

}

@media (max-width: 768px) {
  .consult-head{
    font-size:30px;
  }
  .btn-c{
    padding:10px 15px;
    margin-bottom:30px;
  }
  .consult-button{
    font-size:12px;
    padding-top: 10px;
    
    }
    
}



