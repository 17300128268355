
.career-page{ 
 max-width: 833px;
position: relative;
font-family: 'Montserrat', sans-serif;
font-weight: 400;

}

.all-forms {
    margin-bottom:200px;
}

.career-head{
    margin-bottom: 90px;
    color:#fff;
    margin-top:120px;
    z-index: 10;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
}

@media (max-width:768px) {
    .career-head{
        font-size:30px !important;
    }

    .career-minor-head
{
    font-size: 16px !important;
}
.career-page p{
   font-size: 14px;

}

}

.main-buttons{
position: absolute;
top:0%;
left:50%;
transform: translate(-50%, -50%);
}




.main-button1{
    width:180px;
    height:70px;
    border-radius: 55px !important;
}

.career-page p{
    color:#fff;

}


.career-minor-head
{
    color:#fff;
    margin-bottom: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
}

.work{
    color:#949494;
}


.career-form input{
    background-color: #141414;
    border: none;
    padding:0.967rem 1rem;
    color:#fff;
}

.career-form textarea{
    background-color: #141414;
    border: none;
    color:#fff;
}

.career-btn{
    color:black;
    background-color: #fff;
    padding:0.667rem 1rem;
    transition: 0.3s ease-in-out;
    width:100%;
}

.career-btn:hover{
    background-color: #e7e1e1;
}