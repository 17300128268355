.about-paragraph{
    color: #fff;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.about-head{
    margin-bottom: 90px;
    color:#fff;
    margin-top: 180px;
    z-index: 10;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;

}


@media (min-width:992px) {

    .about-content{
        width:60% !important;
    }
    
   
}

@media (max-width:768px) {
    .about-paragraph{
        font-size: 14px;
    }
    .about-head{
        font-size: 30px!important;
    }
  
}