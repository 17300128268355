
.consulting-page{
    max-width: 833px;
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}


.consult-head{
    margin-bottom:90px;
    color:#fff;
    margin-top:90px;
    z-index: 10;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
}


@media(max-width:768px){

    .consult-head{
        font-size:30px !important;
    }

    .consult-page p{
       font-size: 14px !important;
    
    }

    .consult-minor-head{

        font-size: 16px;
    } 

}
 
.main-buttons{
    position: absolute;
    top:0%;
    left:50%;
    transform: translate(-50%, -50%);
 }
    
.main-button1{
    width:180px;
    height:70px;
    border-radius: 55px !important;
}

.consult-page span{
    display: block;
   font-size:18px;
   color:#fff;

}

.consult-page p{
    color:#fff;

}

.consult-form{
    margin-top:85px;
}

.consult-form input{
    background-color: #141414;
    border: none;
    padding:0.967rem 1rem;
    color:#fff;
} 

.consult-form textarea{
    background-color: #141414;
    border: none;
    color:#fff;
}

.consult-btn{
    color:black;
    background-color: #fff;
    padding:0.667rem 1rem;
    transition: 0.3s ease-in-out;
    width:100%;
}

.consult-btn:hover{
    background-color: #e7e1e1;
}

.consult-minor-head{
    color:#fff;
    margin-bottom:15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
} 