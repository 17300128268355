.privacy-paragraph{
    color: #fff;
    font-size: 20px;
    text-align: justify;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.privacy-head{
    margin-bottom: 90px;
    color:#fff;
    margin-top: 180px;
    z-index: 10;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;

}

@media (min-width:992px) {

    .privacy-content{
        width:60% !important;
    }
    
}

@media (max-width:768px) {

    .privacy-head{
        font-size:30px !important;
    }

  
    
}
