.contact-page {
  z-index: 1000;
  text-align: center;
  max-width: 833px !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.alert {
  padding: 5px 0 2px 0 !important;
  text-align: justify;
}

.contact-head {
  margin-bottom: 90px;
  color: #fff;
  text-transform: uppercase;
  margin-top: 180px;
  z-index: 10;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

@media (max-width: 768px) {
  .contact-head {
    font-size: 30px !important;
  }
}

.contact-page i {
  background-color: #fff;
  border-radius: 50%;
  padding: 18px 20px;
  color: black;
  margin-bottom: 10px;
}

.contact-page span {
  display: block;
  font-size: 18px;
  color: #fff;
}

.contact-page p {
  color: #fff;
}

.contact-form input {
  background-color: #141414;
  border: none;
  padding: 0.967rem 1rem;
  color: #fff;
}

input::placeholder,
textarea::placeholder {
  color: rgb(85, 83, 83) !important;
}

.contact-form textarea {
  background-color: #141414;
  border: none;
  color: #fff;
}

.contact-btn {
  color: black;
  background-color: #fff;
  padding: 0.667rem 5rem;
  transition: 0.3s ease-in-out;
  width: 100%;
  cursor: pointer;
}

.contact-btn:hover {
  background-color: #d0c7c7;
}

.form-control {
  background-color: #141414 !important;
  outline-color: none !important;
  box-shadow: none !important;
  color: #fff !important;
}
