#header {
  position: absolute;
  left: 0;
  top: -45px;
  right: 0;
  transition: all 0.5s;
  z-index: 997;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}


.header-c {
  background: rgba(6, 12, 34, 0.98);
  height: 70px !important;
  position: sticky !important;
}

#header.header-scrolled,
#header.header-inner {
  background: rgba(6, 12, 34, 0.98);
  height: 70px;
}

#header #logo h1 {
  font-size: 36px;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400; 
  letter-spacing: 3px;
  text-transform: uppercase;
}

#header #logo h1 span {
  color: #f82249;
}

#header ul li a{
  font-size:15px !important;
}
#header ul li a:hover{
  color:#fff !important;
}


#header #logo h1 a,
#header #logo h1 a:hover {
  color: #fff;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 300px;
}

@media (max-width: 992px) {
  #header .logo img {
    max-height: 150px;
  }
  #header{
    top:-20px;
  }

  .navbar-nav{
    margin-top:-58px;
    text-align: center;
    background-color: #141414;
  }

  #header ul li a{
    font-size:12px !important;
  }
}

@media (min-width: 397px) and (max-width: 991x) {
  .navbar-nav{
    margin-top:-110px;
    align-items: center;
    background-color: #141414;
  }
}
@media (max-width: 768px) {

.container{
  max-width:100% !important;
}

}
